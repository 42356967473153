import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><Link to="/">Home</Link></li>
	    <li><a href="#experience">Experience</a></li>
	    <li><a href="#projects">Projects</a></li>
	    <li><a href="#skills">Skills</a></li>
        </ul>
    )
}
export default Nav;
