import React from 'react';
import {Link} from "react-router-dom";
import ProgressbarOne from "../progressbar/ProgressbarOne";
import SectionTitleTwo from "../sectionTitle/SectionTitleTwo";

const AboutSix = () => {
    return (
        <div className="about-style-6 rn-section-gapBottom">
            <div className="plr_md--30 plr_sm--30">
                <div className="row row--15">
                    <div className="col-lg-12">
                        <div className="rbt-progress-style-1 mt--60">
                            <SectionTitleTwo
                                Title= "Skills & Passions"
                                textAlign= "text-start mb--40"
                            />
                            <ProgressbarOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSix
