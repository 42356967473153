import React from 'react'
import {Link} from "react-router-dom";
import SEO from "../common/SEO";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopNews from '../common/header/HeaderTopNews';
import Copyright from '../common/footer/Copyright';
import AboutSix from '../elements/about/AboutSix';
import ScrollTop from "../common/footer/ScrollTop";
import ServiceFive from "../elements/service/ServiceFive";
import SectionTitleTwo from "../elements/sectionTitle/SectionTitleTwo";
import PricingOne from "../elements/pricing/PricingOne";
import BrandThree from "../elements/brand/BrandThree";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import ContactForm from "../elements/contact/ContactForm";




const Freelancer = () => {
    return (
        <>
            <SEO title="Awoke Dragon Consulting" />
            <main className="page-wrapper">
                <HeaderTopNews />
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />

                {/* Start Slider area  */}
                <div className="slider-area slider-style-2 variation-3 height-850 bg_image bg_image_fixed theme-shape" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/Liu_Bei_Visiting_Zhuge_Liang_by_Yosa_Buson.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="inner text-start">
                                    <h3 className="description">Welcome to my quiet place.</h3>
                                    <div className="button-group mt--40 mt_sm--20">
                                        <a href="#experience" className="btn-default btn-icon">Knock, knock, knock<i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <a id="experience"><div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <SectionTitleTwo
                           Title= "Experience"
                           textAlign= "text-start"
                        />
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="dark-line"  />
                            </div>
                        </div>
                    </div>
                </div></a>


	        <a id="projects">
                <div className="rwt-portfolio-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitleTwo
                                    Title= "Have you seen my handiwork?"
                                    textAlign= "text-start"
                                />
                            </div>
                        </div>
                        <ServiceFive 
                            serviceStyle = "gallery-style"
                            textAlign = "text-start"
                        />
                    </div>
                </div>
	        </a>

	        <a id="skills">
                <AboutSix />
	        </a>

                <div className="contact-area rn-section-gapBottom">
                    <div className="container">
	                <a id="contact">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitleTwo
                                    Title= "Send a message"
                                    textAlign= "text-start"
                                />
                            </div>
                        </div>
                        <div className="row row--30 mt--40">
                            <div className="col-lg-6 order-2 oder-lg-1 mt_md--30 mt_sm--30">
                                <ContactForm formStyle="contact-form-1" />
                            </div>
                            <div className="col-lg-6 order-1 oder-lg-2">
                                <div className="thumbnail">
                                    <img className="radius w-100" src="./images/about/SkyLanternRichy01.jpg" alt="Corporate" />
                                </div>
                            </div>
                        </div>
	                </a>
                    </div>
                </div>
                <Copyright />
            </main>
            <ScrollTop />
        </>
    )
}
export default Freelancer;
